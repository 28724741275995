<template>
  <!-- 關於 -->
  <div id="AboutBody" class="All_1280 article_description">
    <article class="numero_about">
      <div class="pattern">
        <img src="/images/numero_about.png" alt="" />
      </div>
      <h1>In merito al NUMERO DI EMISSIONE italiano</h1>
      <p>
        Il NUMERO DI EMISSIONE italiano è uno dei biglietti della lotteria che
        riscuote più successo in Italia, e il suo sistema di gioco è
        relativamente semplice. Ogni estrazione viene sorteggiata con una serie
        vincente di numeri di 5 cifre, e il gioco consiste nell'indovinare il
        numero sorteggiato.
      </p>
    </article>
    <div class="foot">
      <img src="/images/numero_foot.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {};
</script>