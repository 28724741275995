import Vue from 'vue';
import VueRouter from 'vue-router';

import HomeView from './views/Home';
import SSCView from './views/SSC';
import PK10View from './views/PK10';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/Lottoger/Number',
    name: 'SSC',
    component: SSCView
  },
  {
    path: '/Lottoger/ItPK10',
    name: 'PK10',
    component: PK10View
  }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

export default router;
