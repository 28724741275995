<template>
  <!-- 規則 -->
  <div class="All_1280 article_description">
    <article class="pk10_rule">
      <div class="pattern">
        <img src="/images/pk10_rule.png" alt="" />
      </div>
      <h1>Regolamento</h1>
      <h2>1. Scommesse</h2>
      <p>
        Ogni PK10 italiano è composto da 10 auto numerate da 1 a 10, in base
        alla classifica dei vincitori. Offriamo decine di modi interessanti tra
        cui scegliere il metodo di gioco.
      </p>
      <h2>2. Quando giocare</h2>
      <p>
        Effettuiamo le premiazioni ogni 60 secondi, per un totale di 1.440 volte
        al giorno, 365 giorni all'anno.
      </p>
      <h2>3. Risultati della lotteria</h2>
      <p>
        Ogni gioco è composto da un totale di 10 auto numerate da 1-10, e
        l'ordine di arrivo è la classifica finale. L’auto che arriva per prima è
        la vincitrice, la seconda è la seconda classificata, la terza auto
        arrivata si assegna il terzo posto, e così via. I risultati della
        competizione vengono visualizzati ogni 60 secondi, e il numero vincente
        comunicato sul nostro sito ufficiale.
      </p>
    </article>
    <div class="foot">
      <img src="/images/pk10_foot.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {};
</script>