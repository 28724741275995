<template>

  <div class="All_1280 pk10_con_Table2">    
    <div class="charts_detail">
      <table class="Dtable Old" width="100%">
        <thead>
          <tr>
            <th width="64" rowspan="2" class="sort" id="tdIssueNo" style="cursor: pointer;">
                Numero di serie<i class="icon arrow_upward"></i>
            </th>
            <th colspan="10">primo</th>
            <th colspan="10">secondo</th>
            <th colspan="10">trezo</th>
            <th colspan="10">quarto</th>
            <th colspan="10">quinto</th>
          </tr>
          
          <tr class="NBold">               
            <th v-for="n in ballSize">{{ n }}</th>   
            <th v-for="n in ballSize">{{ n }}</th>   
            <th v-for="n in ballSize">{{ n }}</th>   
            <th v-for="n in ballSize">{{ n }}</th>   
            <th v-for="n in ballSize">{{ n }}</th>   
          </tr>
        </thead>
        <tbody>
          <tr class="t-single1" v-for="(item, index) in searchResult" :key="index">                                   
            <td>{{ item.issueNo }}</td>
            <td class="lightblue" v-for="n in ballSize">
                <em class="signicon b_1 tenThousand" v-if="n === parseInt(item.drawNumber[0])">{{ n }}</em>
                <em v-else></em>
            </td>
            <td class="lightred" v-for="n in ballSize">
                <em class="signicon b_5 tenThousand" v-if="n === parseInt(item.drawNumber[1])">{{ n }}</em>
                <em v-else></em>
            </td>
            <td class="lightblue" v-for="n in ballSize">
                <em class="signicon b_1 tenThousand" v-if="n === parseInt(item.drawNumber[2])">{{ n }}</em>
                <em v-else></em>
            </td>                                    
            <td class="lightred" v-for="n in ballSize">
                <em class="signicon b_5 tenThousand" v-if="n === parseInt(item.drawNumber[3])">{{ n }}</em>
                <em v-else></em>
            </td>
            <td class="lightblue" v-for="n in ballSize">
                <em class="signicon b_1 tenThousand" v-if="n === parseInt(item.drawNumber[4])">{{ n }}</em>
                <em v-else></em>
            </td>  
          </tr>                   
        </tbody>

        <tfoot id="tfooter">
          <tr class="t-single1">                       
            <td>
                Cumulativo Attuale<br>
                （Totale:<span class="green" id="totalIssue">{{ size }}</span>）
            </td>
               
            <td class="lightblue" v-for="(item, index) in statistics[0]" >
                <span class="times tenThousand darkBlueColor">
                    <em :style="{height: item * 4 + 'px'}">
                        <b>
                            <i>{{item}}</i>
                        </b>
                    </em>
                </span>
            </td>   
            
            <td class="lightred" v-for="(item, index) in statistics[1]" >
                <span class="times thousand redcolor">
                    <em :style="{height: item * 4 + 'px'}">
                        <b>
                            <i>{{item}}</i>
                        </b>
                    </em>
                </span>
            </td>
            
            
            <td class="lightblue" v-for="(item, index) in statistics[2]" >
                <span class="times hundred darkBlueColor">
                    <em :style="{height: item * 4 + 'px'}">
                        <b>
                            <i>{{item}}</i>
                        </b>
                    </em>
                </span>
            </td>                        

            
            <td class="lightred" v-for="(item, index) in statistics[3]" >
                <span class="times ten redcolor">
                    <em :style="{height: item * 4 + 'px'}">
                        <b>
                            <i>{{item}}</i>
                        </b>
                    </em>
                </span>
            </td>    
            
            <td class="lightblue" v-for="(item, index) in statistics[4]" >
                <span class="times bits darkBlueColor">
                    <em :style="{height: item * 4 + 'px'}">
                        <b>
                            <i></i>
                        </b>
                    </em>
                </span>
            </td>                        
          </tr>
          <tr class="NBold">
            <td rowspan="2" style="background: none">&nbsp;</td>
            <td v-for="n in ballSize">{{ n }}</td>   
            <td v-for="n in ballSize">{{ n }}</td>   
            <td v-for="n in ballSize">{{ n }}</td>   
            <td v-for="n in ballSize">{{ n }}</td>   
            <td v-for="n in ballSize">{{ n }}</td>  
          </tr>
          <tr>
            <td colspan="10">primo</td>
            <td colspan="10">secondo</td>
            <td colspan="10">trezo</td>
            <td colspan="10">quarto</td>
            <td colspan="10">quinto</td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: ['size'],
  inject: {
    service: 'service'
  },
  data: () => {
    return {     
      searchResult: [],
      statistics:[],
      ballSize: [...Array(10)].map((_, index) => index)
    }
  },
  mounted() {
    this.getData(this.size);
  },
  methods: {
    async getData(range) {            
      let result = await this.service.getNumeroSelectDataRange(range); 
      this.searchResult = result.issueInfo;
      this.statistics = result.statistics;
    }
  },
  watch: {
    size: function(val){
      this.getData(val)
    }
  }
};
</script>