import Vue from 'vue';
import Vuex from 'vuex'
import App from './App.vue';
import router from './router';
import service from './service';
import i18n from './i18n';

import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/themes/base/core.css';
import 'jquery-ui/themes/base/datepicker.css';
import 'jquery-ui/themes/base/theme.css';
window.$ = window.jQuery = require('jquery');

Vue.use(i18n);
Vue.config.productionTip = false;
Vue.use(Vuex);

const store = new Vuex.Store({
  state:{
    issueNewest: {
      ssc: {drawNumbers:['?', '?', '?', '?', '?']},
      pk10: {drawNumbers:['?', '?', '?', '?', '?', '?', '?', '?', '?', '?']},
      seconds: 0
    }
  },
  mutations: {
    updateIssueNewest (state, value) {                  
      if (value && value.ssc){
        state.issueNewest.ssc = value.ssc
      }

      if (value && value.pk10){
        state.issueNewest.pk10 = value.pk10;
      }

      if (value && value.seconds){
        state.issueNewest.seconds = value.seconds;
      }
    },
    updateCDSeconds(state, value){      
      state.issueNewest.seconds = value;      
    }
  }
})

new Vue({
  provide() {
    return { service };
  },
  store: store,
  router,
  render: h => h(App)
}).$mount('#app')

