<template>
  <!-- 歷史期號 -->
  <div>
    <div class="range_select" id="Statistiche1">
      <span>Intervallo statistico:</span>
      <a href="javascript:void(0);" :class="{active: activeCountBtn === 'size30' }" class="Range_a" @click="changeRange(30)">ultimo 30</a>
      <a href="javascript:void(0);" :class="{active: activeCountBtn === 'size50' }" class="Range_a" @click="changeRange(50)">ultimo 50</a>
      <a href="javascript:void(0);" :class="{active: activeCountBtn === 'size100' }" class="Range_a" @click="changeRange(100)">ultimo 100</a>
    </div>
    <statistics-view :size="statisticsSize"></statistics-view>    
  </div>  
</template>

<script>
import StatisticsView from './StatisticsView.vue';
export default {
  components: { StatisticsView },
  data() {
    return {
      activeCountBtn: 'size30',
      statisticsSize: 30
    }    
  },
  methods: {
    changeRange(size){
      this.activeCountBtn = 'size' + size;
      this.statisticsSize = size;
    }
  }
};
</script>