<template>
  <!-- 關於 -->
  <div class="All_1280 article_description">
    <article class="pk10_about">
      <div class="pattern">
        <img src="/images/pk10_about.png" alt="" />
      </div>
      <h1>In merito al PK10 italiano</h1>
      <p>
        Il PK10 italiano è uno dei biglietti della lotteria che riscuote più
        successo in Italia, e il suo sistema di gioco è relativamente semplice.
        Nel PK10 gareggiano 10 auto, e il gioco consiste nell'indovinare la
        classifica finale delle auto da 1 a 10.
      </p>
    </article>
    <div class="foot">
      <img src="/images/pk10_foot.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {};
</script>