<template>
  <!-- 規則 -->
  <div id="RuleBody" class="All_1280 article_description">
    <article class="numero_rule">
      <div class="pattern">
        <img src="/images/numero_rule.png" alt="" />
      </div>
      <h1>Informazioni generali riguardanti il NUMERO DI EMISSIONE italiano</h1>
      <h2>1. Scommesse</h2>
      <p>
        Ogni numero vincente del NUMERO DI EMISSIONE è composto da un numero di
        5 cifre, nella stessa posizione selezionata. Offriamo decine di modi
        interessanti tra cui scegliere il metodo di gioco.
      </p>
      <h2>2. Quando giocare</h2>
      <p>
        Le premiazioni vengono effettuate ogni 60 secondi, per un totale di
        1.440 volte al giorno, 365 giorni all'anno.
      </p>
      <h2>3. Risultati dell'estrazione</h2>
      <p>
        Ogni estrazione viene sorteggiata con una serie vincente di numeri a 5
        cifre, in decine di migliaia, mille, cento, dieci e ad una cifra, con
        numeri da 0-9. Il numero vincente viene visualizzato ogni 60 secondi, e
        il numero vincente comunicato sul nostro sito ufficiale.
      </p>
    </article>
    <div class="foot">
      <img src="/images/numero_foot.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {};
</script>