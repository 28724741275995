<template>
  <!-- 歷史期號 -->
  <div>
    <div class="searchbar">
      <span>Ricerca:</span>
      <select class="select_year" v-model="selYear" @change="changeYear()">        
        <option class="yearItem" v-for="n in yearList" :value="n" :key="n">{{n}}</option>
      </select>
      <select class="select_month" v-model="selMonth" @change="changeMonth()">
        <option :value="-1">si prega di scegliere</option>
        <option class="monthItem" v-for="(item, index) in monthText" :value="index" :key="index">{{item}}</option>
      </select>
      <select class="select_day" v-model="selDay" @change="changeDay()">
        <option :value="-1">si prega di scegliere</option>
        <option class="dayItem" v-for="n in monthLen" :key="n" :value="n">{{padLeft(n, '0', 2)}}</option>
      </select>
      <select name="select" class="select_time" v-model="selHour">
        <option :value="-1">si prega di scegliere</option>
        <option class="timeItem" v-for="n in hourLen" :key="n - 1" :value="n - 1">{{padLeft(n - 1, '0', 2) + ': 00'}}</option>
      </select>
    </div>

    <table border="0" cellspacing="0" cellpadding="0" class="All_1280 pk10_con_Table" v-if="lotteryType==='ssc'">
      <thead class="t-head">
        <tr class="table_title">
          <th width="220" class="th1">Numero di serie</th>
          <th width="216">Risultati</th>
          <th width="842" class="th2">Data Di Estrazione</th>
        </tr>
      </thead>
      <tbody id="tableUI">        
        <tr v-for="(item, index) in searchResult" :key="index" :class="(index % 2 == 0) ? 'table_color_white' : 'table_color_gray'" class="t-single">
          <td style="text-align: center">{{ item.issueNo }}</td>
          <td style="text-align: center">{{ item.issueTime }}</td>
          <td style="text-align: center" class="NumberSize">
            <span class="lottery_ball" v-for="(ball, ballIdx) in item.drawNumber" :key="ballIdx"><p>{{ball}}</p></span>
          </td>
        </tr>
      </tbody>
    </table>

    <table border="0" cellspacing="0" cellpadding="0" class="All_1280 pk10_con_Table" v-if="lotteryType==='pk10'">
      <thead class="t-head">
        <tr class="table_title">
          <th class="th1">Numero di serie</th>
          <th>Risultati</th>
          <th>Data Di Estrazione</th>
          <th>Prima e seconda somma</th>
          <th class="th2">Drago - Tigre</th>
        </tr>
      </thead>
      <tbody id="tableUI">        
        <tr v-for="(item, index) in searchResult" :key="index" :class="(index % 2 == 0) ? 'table_color_white' : 'table_color_gray'" class="t-single">
          <td style="text-align: center">{{ item.issueNo }}</td>
          <td style="text-align: center">{{ item.issueTime }}</td>
          <td style="text-align: center" class="NumberSize">
            <span v-for="(ball, ballIdx) in item.drawNumber" :key="ballIdx" class="myBackgroundPic" :style="{'background-image': 'url(/images/n' + ball + '.png)', paddingRight: 5 + 'px'}" >
                <span class="myText">{{ball}} </span>
            </span>
          </td>
          <td style="text-align: left"><span class="txt_Indentation">{{item.sumResult}}</span></td>
          <td style="text-align: center" class="txt_spacing">{{item.td}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped>
.myBackgroundPic {
  display:inline-block;
  width: 38px;
  height: 46px;
  background-size: 38px 46px;
  background-repeat: no-repeat;
}
.myBackgroundPic > .myText{
  opacity: 0;
}
</style>

<script>

export default {
  props: ['lotteryType'],
  inject: {
    service: 'service'
  },
  data: () => {
    return {
      yearList: [],
      monthLen: 0,
      monthText: [], 
      hourLen: 24,
      selYear: -1,
      selMonth: -1,
      selDay: -1,
      selHour: -1,
      searchResult: []
    }
  },
  mounted() {
    let timeText = this.$momentTz.format('YYYY-MM-DD HH:00:00');     
    var currentTime = new Date(timeText);

    this.monthText = this.$moment.months(); 
    this.selYear = currentTime.getFullYear();
    this.selMonth = currentTime.getMonth();
    this.selDay = currentTime.getDate();
    this.selHour = currentTime.getHours();

    for (let i = 2015; i <= currentTime.getFullYear(); i++){
      this.yearList.push(i);
    }   

    this.updateDateSelect();
  },
  methods: {
    updateDateSelect() {
      let timeText = this.$momentTz.format('YYYY-MM-DD HH:00:00');     
      var currentTime = new Date(timeText);

      let currentYear = currentTime.getFullYear();
      let currentMonth = currentTime.getMonth();
      let currentDay = currentTime.getDate();

      if (currentYear === this.selYear) {
        this.monthText = this.monthText.slice(0, currentMonth + 1);
      } else {
        this.monthText = this.$moment.months();  
      }
      
      if (currentYear === this.selYear && currentMonth === this.selMonth) {
        this.monthLen = currentDay;
      } else {
        this.monthLen = new Date(this.selYear, this.selMonth + 1, 0).getDate();
      }

      if (currentYear === this.selYear && 
          currentMonth === this.selMonth && 
          currentDay === this.selDay) 
      {
        this.hourLen = currentTime.getHours() + 1;
      } else {
        this.hourLen = 24;
      }
    },
    changeYear() {
      this.selMonth = -1;
      this.selDay = -1;
      this.selHour = -1;

      this.updateDateSelect();
    },
    changeMonth() {
      this.selDay = -1;
      this.selHour = -1;

      this.updateDateSelect();
    },
    changeDay() {
      this.selHour = -1;

      this.updateDateSelect();
    },
    async getData(queryDate) {      
      if (this.lotteryType === 'ssc') {
        this.searchResult = await this.service.getNumeroSelectDataAsync(queryDate); 
      } else if (this.lotteryType === 'pk10') {
        this.searchResult = await this.service.getPK10SelectDataAsync(queryDate); 
      }
    }
  },
  computed:{
    selFullDate: function ()  {
      return this.selYear + '-' + 
        this.padLeft((this.selMonth + 1), '0', 2) + '-' + 
        this.padLeft(this.selDay, '0', 2) + ' ' + 
        this.padLeft(this.selHour, '0', 2) + ':00:00';
    }
  },
  watch: {
    selFullDate: function (val, oldVal)  {      
      if (this.selYear <= 0 || (this.selMonth + 1) <= 0 || this.selDay <= 0 || this.selHour < 0) return;      
      this.getData(val);      
    }
  }
};
</script>