<template>
  <div>
    <div class="Adv Adv_in">
      <img src="/images//pk10_banner.jpg" />

      <div class="pk10_advNumber">
        <div class="All_1280" style="text-align: center" v-if="(newestDrawNumbers && newestDrawNumbers[0] !== '?')">
          <img 
            v-for="(item, index) in newestDrawNumbers"
            :key="index"
            :src="'/images/' + 'n' + item + '.png'"/>
        </div>
      </div>
    </div>

    <div class="Home_con">
      <div class="tab_btn">
        <ul>
          <li @click="activeBtn = 'Precedenti'">
            <a href="javascript:void(0)" :class="{active: activeBtn === 'Precedenti' }">Estrazioni<br />Precedenti</a>
          </li>
          <li @click="activeBtn = 'Statistiche'">
            <a href="javascript:void(0)" :class="{active: activeBtn === 'Statistiche' }">Statistiche</a>
          </li>
          <li @click="activeBtn = 'About'">
            <a href="javascript:void(0)" :class="{active: activeBtn === 'About' }">Presentazione</a>
          </li>
          <li @click="activeBtn = 'Background'">
            <a href="javascript:void(0)" :class="{active: activeBtn === 'Background' }">Informazioni<br />generali</a>
          </li>
          <li @click="activeBtn = 'Rule'">
            <a href="javascript:void(0)" :class="{active: activeBtn === 'Rule' }">Regolamento</a>
          </li>
        </ul> 
      </div>
    </div>
    <precedenti-list-by-date v-show="activeBtn === 'Precedenti'" :lotteryType="typeName"></precedenti-list-by-date>
    <statistics-chart v-show="activeBtn === 'Statistiche'"></statistics-chart>>
    <pk-10-about v-show="activeBtn === 'About'"></pk-10-about>
    <pk-10-background v-show="activeBtn === 'Background'"></pk-10-background>
    <pk-10-rule v-show="activeBtn === 'Rule'"></pk-10-rule>
  </div>
</template>

<style scoped>
@import "/css/tabs_article.css";
@import "/css/style.css";
@import "/css/layer.css";
</style>
<script>
import pk10About from '../components/PK10About.vue';
import pk10Background from '../components/PK10Background.vue';
import pk10Rule from '../components/PK10Rule.vue';
import StatisticsChart from '../components/StatisticsChart.vue';
import PrecedentiListByDate from '../components/PrecedentiListByDate.vue';

export default {
  inject: {
    service: 'service'
  },
  components: {
    pk10About, 
    pk10Background, 
    pk10Rule,
    PrecedentiListByDate,
    StatisticsChart
  },
  data() {
    return {
      activeBtn: "Precedenti",
      typeName: 'pk10',
      newestDrawNumbers: []
    };
  },
  beforeMount() {    
    //this.checkScriptIsExistAndCreate('/js/highstock.js', false);
    this.checkScriptIsExistAndCreate('/js/Itpk10.js', true);

    if (!this.$store.state.issueNewest.pk10.drawNumbers || this.$store.state.issueNewest.pk10.drawNumbers[0] === '?'){
      this.getNewestAsync();
    } else {
      this.newestDrawNumbers = this.$store.state.issueNewest.pk10.drawNumbers;
    }
  },
  methods: {
    checkScriptIsExistAndCreate(jsPath, isDel){
      let scripts = document.getElementsByTagName('script');

      let isFind = false;
      for (let i=0; i<scripts.length; i++) {
        if (scripts[i].src.indexOf(jsPath) > -1 ){
          isFind = true;
          if (isDel){
            scripts[i].parentElement.removeChild(scripts[i]);
          } else{
            break;
          }
        }
      }
      
      if (isDel || !isFind){
        let loadScript = document.createElement("script");
        loadScript.setAttribute("src", jsPath);
        document.head.appendChild(loadScript);
      }
    },
    async getNewestAsync() {
      var response = await this.service.getCurrentNumberStringAsync();  

      if (response &&
          (response.pk10 && response.pk10.drawNumbers && response.pk10.drawNumbers.length > 0)) {

        this.newestDrawNumbers = response.pk10.drawNumbers;
        this.$store.commit('updateIssueNewest', { pk10: response.pk10 });    
      }
    }
  }
};
</script>