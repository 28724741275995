<template>
  <div>
    <!-- 首頁最新一期 -->
    <div class="Adv Adv_in">
        <img src="/images/numero_banner.jpg">
        <div class="pk10_advNumber">
            <div class="All_1280" style="text-align: center;">
                <span class="lottery_ball_b" v-for="(item, index) in newestDrawNumbers" :key="index">
                  <p>{{item}}</p>
                </span>               
            </div>
        </div>
    </div>
    <div class="Home_con">     
      <div class="tab_btn">
        <ul>
          <li @click="activeBtn = 'Precedenti'">
            <a href="javascript:void(0)" :class="{active: activeBtn === 'Precedenti' }">Estrazioni<br />Precedenti</a>
          </li>
          <li @click="activeBtn = 'Statistiche'">
            <a href="javascript:void(0)" :class="{active: activeBtn === 'Statistiche' }">Statistiche</a>
          </li>
          <li @click="activeBtn = 'About'">
            <a href="javascript:void(0)" :class="{active: activeBtn === 'About' }">Presentazione</a>
          </li>
          <li @click="activeBtn = 'Background'">
            <a href="javascript:void(0)" :class="{active: activeBtn === 'Background' }">Informazioni<br />generali</a>
          </li>
          <li @click="activeBtn = 'Rule'">
            <a href="javascript:void(0)" :class="{active: activeBtn === 'Rule' }">Regolamento</a>
          </li>
        </ul>
      </div>
    </div>
    <precedenti-list-by-date v-show="activeBtn === 'Precedenti'" :lotteryType="typeName"></precedenti-list-by-date>
    <precedenti-list-by-count v-show="activeBtn === 'Statistiche'"></precedenti-list-by-count>
    <number-about v-show="activeBtn === 'About'"></number-about>
    <number-background v-show="activeBtn === 'Background'"></number-background>
    <number-rule v-show="activeBtn === 'Rule'"></number-rule>
  </div>
</template>

<style scoped>
@import '/css/tabs_article.css';
@import '/css/style.css';
@import '/css/layer.css';
</style>

<script>
import numberAbout from '../components/NumberAbout.vue';
import numberBackground from '../components/NumberBackground.vue';
import numberRule from '../components/NumberRule.vue';
import PrecedentiListByCount from '../components/PrecedentiListByCount.vue';
import PrecedentiListByDate from '../components/PrecedentiListByDate.vue';
export default {
  inject: {
    service: 'service'
  },
  components: {
    numberAbout, 
    numberBackground, 
    numberRule,
    PrecedentiListByDate,
    PrecedentiListByCount
  },
  data() {
    return {
      activeBtn: 'Precedenti',
      typeName: 'ssc',
      newestDrawNumbers: []
    }    
  },
  beforeMount() {    
    if (!this.$store.state.issueNewest.ssc.drawNumbers || this.$store.state.issueNewest.ssc.drawNumbers[0] === '?'){
      this.getNewestAsync();
    } else {
      this.newestDrawNumbers = this.$store.state.issueNewest.ssc.drawNumbers;
    }
  },
  methods: {   
    async getNewestAsync() {
      var response = await this.service.getCurrentNumberStringAsync();  

      if (response &&          
          (response.numero && response.numero.drawNumbers && response.numero.drawNumbers.length > 0)) {

        this.newestDrawNumbers = response.numero.drawNumbers;
        this.$store.commit('updateIssueNewest', { ssc: response.numero });    
      }
    }
  }
}

</script>