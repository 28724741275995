<template>
  <footer>
    <div class="Footer_con">
      <div class="Footer_Logo">
        <img src="/images/f_logo1.png">
        <img src="/images/f_logo2.png">
        <img src="/images/f_logo3.png">
      </div>
      <p>
        Tutte le informazioni fornite da questo sito sono solo a scopo di riferimento e si consiglia di seguire e seguire le leggi della propria zona.<br>
        In ogni caso, la legge che costringe i visitatori a violare la regione appartiene alla propria responsabilità, le conseguenze di questo sito non saranno responsabili.<br>
        Copyright © 2017-2018 italia pk10
      </p>
    </div>
  </footer>
</template>

<script>
export default {

}
</script>