var moment = require('moment-timezone');

const timeZone = 'Europe/Rome';
const germanCode = 'it';

// Ex:Januar 28,2020 01:39 pm
const dateTimeFormatter = 'MMMM DD,YYYY h:mm a';

moment.locale(germanCode);

const install = (Vue, options) => {
    Vue.prototype.$moment = moment;

    Vue.prototype.$momentTz = moment.tz(timeZone);

    Vue.prototype.$momentTzFromValue = value => moment.tz(value, timeZone);

    Vue.filter('formatDateTime', value => (value && moment.tz(value, timeZone).format(dateTimeFormatter)) || '');

    Vue.prototype.padLeft = (num, char, size) => {
        num = num.toString();
        while (num.length < size) num = char + num;
        return num;
    }
};

export default install;