<template>
  <div>
    <office-header></office-header>
    <router-view></router-view>
    <office-footer></office-footer>
  </div>
</template>

<script>
import headerComponent from './components/Header';
import footerComponent from './components/Footer';
const executeDelay = 1500;
const maxExecuteTimes = 10;
export default {
  components: {
    officeHeader: headerComponent,
    officeFooter: footerComponent
  },
  inject: {
    service: 'service'
  },
  methods: {
    async getNewestAsync() {
      clearInterval(this.issueNoCountdownTimerId);

      var response = null;
      var currentExecuteTimes = 1;      
      
      // 取不到就一直取，直到超過最大上限
      this.issueNoCountdownTimerId = setInterval(async () => {

        if (currentExecuteTimes > maxExecuteTimes) {
          clearInterval(this.issueNoCountdownTimerId);
        }
        else {
          currentExecuteTimes++;
          response = await this.service.getCurrentNumberStringAsync(); 
  
          if (response &&
             (response.pk10 && response.pk10.drawNumbers && response.pk10.drawNumbers.length > 0) &&
             (response.numero && response.numero.drawNumbers && response.numero.drawNumbers.length > 0)) {
            
            clearInterval(this.issueNoCountdownTimerId);             

            this.$store.commit('updateIssueNewest', { pk10: response.pk10, ssc: response.numero, seconds: response.seconds });    
            
            this.countdown();
          }
        }
      }, executeDelay);
    },
    countdown() {
      clearInterval(this.countdownTimerId);

      this.countdownTimerId = setInterval(() => {        
        if (this.$store.state.issueNewest.seconds > 0) {          
          this.$store.commit('updateCDSeconds', this.$store.state.issueNewest.seconds - 1);                
        }
        else {
          clearInterval(this.countdownTimerId);
          this.getNewestAsync();
        }
      }, 1000);
    }
  },
  async created() {
    await this.getNewestAsync();
  },
  beforeDestroy() {    
    clearInterval(this.countdownTimerId);
    clearInterval(this.issueNoCountdownTimerId);
  }
}
</script>