import request from './request';

const getCurrentNumberStringAsync = () => request.get('Lottoger/GetCurrentNumberString', {});

const getNumeroSelectDataAsync = (searchDate) => request.get('Lottoger/GetNumeroSelectData', {searchDate});

const getNumeroSelectDataRange = (range) => request.get('Lottoger/GetNumeroSelectDataRange', {range});

const getPK10SelectDataAsync = (searchDate) => request.get('Lottoger/GetPK10SelectData', {searchDate});

const getStatisticDataAsync = (issueno) => request.get('Lottoger/GetStatisticData', {issueno});

export default {
    getCurrentNumberStringAsync,
    getNumeroSelectDataAsync,
    getNumeroSelectDataRange,
    getPK10SelectDataAsync,
    getStatisticDataAsync
};