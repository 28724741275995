<template>
  <!-- 背景 -->
  <div class="All_1280 article_description">
    <article class="pk10_story">
      <div class="pattern">
        <img src="/images/pk10_story.png" alt="" />
      </div>
      <h1>Informazioni generali sul PK10 italiano</h1>
      <p>
        Il PK10 italiano è una lotteria emessa da Lottomatica e uno dei
        biglietti della lotteria più popolari in Italia. L'Italia è stata la
        prima nazione nel mondo ad emettere biglietti della lotteria. In Italia,
        il governo autorizza la licenza alla pubblicazione nazionale e di
        vendita di biglietti della lotteria solo a due società, vale a dire la
        lotteria Matic (Lottomatica) e Hissar (Sisal), sotto supervisione
        dell'Agenzia dei Monopoli di Stato. L'Agenzia dei Monopoli di Stato è
        l'ente normativo dell'industria monopolistica italiana, compresa
        l'industria dei giochi, che nel 1988 ha assunto gradualmente la gestione
        della lotteria italiana. Le principali funzioni dell'Agenzia dei
        Monopoli di Stato comprendono la riscossione delle tasse sulle attività
        di gioco di amministrazione, la lotta contro il gioco d'azzardo
        illegale, la supervisione delle agenzie di lotteria e la responsabilità
        sociale delle imprese.
      </p>
    </article>
    <div class="foot">
      <img src="/images/pk10_foot.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {};
</script>