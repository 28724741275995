<template>

  <div id="Statistiche2" style="display: none">
    <h2 class="PK10-2_title">Il numero di vittorie</h2>

    <div class="All_1280 pk10_con_Table table_color_white">
        <div class="Number_1050px">
            <span v-for="(item, index) in statisticData" :key="index" >
              <img :src="'/images/n'+ padLeft(index + 1, '0', 2) +'.png'" alt="">
              {{item}}
            </span>
        </div>
    </div><br>
    <h2 class="PK10-2_title">Analisi delle tendenze</h2>
    <table border="0" cellspacing="0" cellpadding="0" class="All_1280 pk10_con_Table">
        <tbody>
            <tr class="table_title">
                <th class="th1" onclick="changeTab(1)"><a href="javascript:void(0)" class="chart_a_link active" id="inside_chart1">primo</a></th>
                <th onclick="changeTab(2)"><a href="javascript:void(0)" class="chart_a_link" id="inside_chart2">secondo</a></th>
                <th onclick="changeTab(3)"><a href="javascript:void(0)" class="chart_a_link" id="inside_chart3">trezo</a></th>
                <th onclick="changeTab(4)"><a href="javascript:void(0)" class="chart_a_link" id="inside_chart4">quarto</a></th>
                <th onclick="changeTab(5)"><a href="javascript:void(0)" class="chart_a_link" id="inside_chart5">quinto</a></th>
                <th onclick="changeTab(6)"><a href="javascript:void(0)" class="chart_a_link" id="inside_chart6">sesto</a></th>
                <th onclick="changeTab(7)"><a href="javascript:void(0)" class="chart_a_link" id="inside_chart7">setimmo</a></th>
                <th onclick="changeTab(8)"><a href="javascript:void(0)" class="chart_a_link" id="inside_chart8">ottavo</a></th>
                <th onclick="changeTab(9)"><a href="javascript:void(0)" class="chart_a_link" id="inside_chart9">nono</a></th>
                <th class="th2" onclick="changeTab(10)">
                  <a href="javascript:void(0)" class="chart_a_link" id="inside_chart10">diecimo</a>
                </th>
            </tr>
            <tr>
                <td colspan="10" style="text-align: center" class="table_color_white">
                    <div id="inside_tab1" class="tab_content"></div>
                    <div id="inside_tab2" class="tab_content" style="display: none"></div>
                    <div id="inside_tab3" class="tab_content" style="display: none"></div>
                    <div id="inside_tab4" class="tab_content" style="display: none"></div>
                    <div id="inside_tab5" class="tab_content" style="display: none"></div>
                    <div id="inside_tab6" class="tab_content" style="display: none"></div>
                    <div id="inside_tab7" class="tab_content" style="display: none"></div>
                    <div id="inside_tab8" class="tab_content" style="display: none"></div>
                    <div id="inside_tab9" class="tab_content" style="display: none"></div>
                    <div id="inside_tab10" class="tab_content" style="display: none"></div>
                </td>
            </tr>
        </tbody>
    </table>
  </div>
</template>

<script>
export default {
  inject: {
    service: 'service'
  },
  data: () => {
    return {     
      statisticData: []
    }
  },
  mounted() {
    this.getData(this.size);
  },
  methods: {
    async getData(issueNo) {            
      let result = await this.service.getStatisticDataAsync(issueNo);      
      if (result){
        this.statisticData = result.lotteryPK10StatisticData.slice(1);
      }
    }
  },
  watch:{
    '$store.state.issueNewest.pk10':function(newVal){     
      if (newVal){
        this.getData(newVal.issueNo);
      }
    }
  }
};
</script>