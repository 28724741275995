<template>
  <div>
    <div class="Adv">        
      <ul class="bxslider">
        <li><img src="/images/home_banner.jpg"></li>
        <li><img src="/images/home_banner2.jpg"></li>
        <li><img src="/images/home_banner3.jpg"></li>
      </ul>
    </div>

    <div class="Home_con"> 
      <div class="Home_con_img" id="Home_con_img2">          
        <img src="/images/home_adv2.jpg">
        <div class="lottery_home_bg" id="lottery_home_bg2" style="display:none">
          <h2 class="lottery_home_t">ULTIMI RISULTATI</h2>
          <div class="lottery_home_number" id="lottery_home_number2" >           
            <span class='ball' v-for="(item, index) in this.$store.state.issueNewest.ssc.drawNumbers" :key="index">{{item}}</span>
          </div>
          <div class="btn1"><router-link :to="{ name: 'SSC' }">Tutti numberi vincenti &gt;&gt;</router-link></div>
        </div>
      </div>

      <div class="Home_con_img" id="Home_con_img3"> 
        <img src="/images/home_adv3.jpg">
        <div class="lottery_home_bg" id="lottery_home_bg3" style="display:none;">
          <h2 class="lottery_home_t">ULTIMI RISULTATI</h2>
          <div class="lottery_home_number" id="lottery_home_number3" >      
            <span class='ball' v-for="(item, index) in this.$store.state.issueNewest.pk10.drawNumbers" :key="index">{{item}}</span>            
          </div>
          <div class="btn1"><router-link :to="{ name: 'PK10' }">Tutti numberi vincenti &gt;&gt;</router-link></div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
@import "/css/jquery.bxslider.css";
</style>
<script>
export default {
  beforeMount() {
    let loadJsPath = '/js/home.js';
    let scripts = document.getElementsByTagName('script');
    for (let i=0; i<scripts.length; i++) {      
      if (scripts[i].src.indexOf(loadJsPath)> -1){
        scripts[i].parentElement.removeChild(scripts[i]);
      }      
    }

    let loadScript1 = document.createElement("script");
    loadScript1.setAttribute("src", loadJsPath);
    document.head.appendChild(loadScript1);
  }
}
</script>
